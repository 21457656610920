import revive_payload_client_fKklV3A48s from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ior_d030d0c852edf095f8900fce27f61b42/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_pxEnRfrfdY from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ior_d030d0c852edf095f8900fce27f61b42/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_4qiE1IDGY7 from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ior_d030d0c852edf095f8900fce27f61b42/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_0INWEhi1Uk from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ior_d030d0c852edf095f8900fce27f61b42/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_L33IL102i9 from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ior_d030d0c852edf095f8900fce27f61b42/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_wW51IrFBIc from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ior_d030d0c852edf095f8900fce27f61b42/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_heryvJdgoN from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ior_d030d0c852edf095f8900fce27f61b42/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/builds/robustastudio/mazaya/pancho-v3/.nuxt/components.plugin.mjs";
import prefetch_client_Uq48HGUuQv from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.4_eslint@8.57.0_idb-keyval@6.2.1_ior_d030d0c852edf095f8900fce27f61b42/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_Ky7tqRBcum from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.21.2_vite_0931d6263608fc8f1a455bfa62760dd4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_ZUplTbPwmE from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.2.6_magicast@0.3.5_rollup@4.21.2_vite_0931d6263608fc8f1a455bfa62760dd4/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import nuxt_plugin_32B1TYVd89 from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt-delay-hydration@1.3.8_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.js";
import plugin_vJMX8vIrJ6 from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/nuxt-jsonld@2.0.8_typescript@5.5.4/node_modules/nuxt-jsonld/dist/runtime/plugin.js";
import gtm_client_MJ6Lwxxm1t from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@robustastudio+e-commerce@1.42.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22_c98741039d0be302b8031b325ad742c2/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/gtm.client.mjs";
import ssr_caching_ixEgSTdKLF from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@robustastudio+e-commerce@1.42.0_magicast@0.3.5_rollup@4.21.2_vite@5.4.3_@types+node@22_c98741039d0be302b8031b325ad742c2/node_modules/@robustastudio/e-commerce/dist/runtime/module-plugins/ssr-caching.mjs";
import i18n_oNyGlAf6A5 from "/builds/robustastudio/mazaya/pancho-v3/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_magicast@0.3.5_rollup@4.21.2_vue@3.5.3_typescript@5.5.4__webpack-sources@3.2.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import css_global_vars_client_8R4IaJFvRf from "/builds/robustastudio/mazaya/pancho-v3/plugins/css-global-vars.client.ts";
import loading_GxQ2Z9Goxa from "/builds/robustastudio/mazaya/pancho-v3/plugins/loading.ts";
import purify_html_Hz9hnAad5c from "/builds/robustastudio/mazaya/pancho-v3/plugins/purify-html.ts";
import setup_0lDNjxjaWh from "/builds/robustastudio/mazaya/pancho-v3/plugins/setup.ts";
export default [
  revive_payload_client_fKklV3A48s,
  unhead_pxEnRfrfdY,
  router_4qiE1IDGY7,
  payload_client_0INWEhi1Uk,
  navigation_repaint_client_L33IL102i9,
  check_outdated_build_client_wW51IrFBIc,
  chunk_reload_client_heryvJdgoN,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Uq48HGUuQv,
  pwa_icons_Ky7tqRBcum,
  pwa_client_ZUplTbPwmE,
  nuxt_plugin_32B1TYVd89,
  plugin_vJMX8vIrJ6,
  gtm_client_MJ6Lwxxm1t,
  ssr_caching_ixEgSTdKLF,
  i18n_oNyGlAf6A5,
  css_global_vars_client_8R4IaJFvRf,
  loading_GxQ2Z9Goxa,
  purify_html_Hz9hnAad5c,
  setup_0lDNjxjaWh
]